



import { Component, Watch, Vue } from 'vue-property-decorator';

@Component
export default class P0154 extends Vue {
  @Watch('$auth.loading', { immediate: true })
  redirectToOpGift() {
    this.$router.replace({
      name: 'odakyu-point',
      query: {
        tab: 'op-gift'
      }
    });
  }
}
